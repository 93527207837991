@tailwind base;
@tailwind components;
@tailwind utilities;

/* .App {
  text-align: center;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  border: none;
} */
